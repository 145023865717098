export const webserver = (method, data, callback) => {
    var wsUrl = "https://immac.sw67.ca/ws/ngm/" + method;
    var strData = JSON.stringify(data);
    const options = {
        method: 'POST',
        body: strData,
        headers: {
            'Content-Type': 'application/json'
        }
    }
    fetch(wsUrl, options)
        .then(res => res.json())
        .then(callback)
        .catch(err => console.log(err))
}

export const fe_ws = (method, data, callback) => {
    var wsUrl = "https://newglobal.market/ws/facturae0/" + method;
    var strData = JSON.stringify(data);
    const options = {
        method: 'POST',
        body: strData,
        headers: {
            'Content-Type': 'application/json'
        }
    }
    fetch(wsUrl, options)
        .then(res => res.json())
        .then(callback)
        .catch(err => console.log(err))
}


export const otp_WS = (method, data, callback) => {
    var wsUrl = "https://puntos.club/ws/otp_app_ws/" + method;
    var strData = JSON.stringify(data);
    const options = {
        method: 'POST',
        body: strData,
        headers: {
            'Content-Type': 'application/json'
        }
    }
    fetch(wsUrl, options)
        .then(res => res.json())
        .then(callback)
        .catch(err => console.log(err))
}



export const getToday = () => {
    var fullDate = new Date();
    var twoDigitMonth = fullDate.getMonth() + 1 + '';
    if (twoDigitMonth.length == 1) twoDigitMonth = "0" + twoDigitMonth;
    var twoDigitDate = fullDate.getDate() + "";
    if (twoDigitDate.length == 1) twoDigitDate = "0" + twoDigitDate;
    return fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate;
}

export const getdays_ago = (days) => {
    var fullDate = new Date();
    fullDate.setDate(fullDate.getDate() + days);
    var twoDigitMonth = fullDate.getMonth() + 1 + '';
    if (twoDigitMonth.length == 1) twoDigitMonth = "0" + twoDigitMonth;
    var twoDigitDate = fullDate.getDate() + "";
    if (twoDigitDate.length == 1) twoDigitDate = "0" + twoDigitDate;
    return fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate;
}

export const getIssueDate = (days) => {
    var fullDate = new Date();
    fullDate.setDate(fullDate.getDate() - days);
    var twoDigitMonth = fullDate.getMonth() + 1 + "";
    if (twoDigitMonth.length == 1) twoDigitMonth = "0" + twoDigitMonth;
    var twoDigitDate = fullDate.getDate() + "";
    if (twoDigitDate.length == 1) twoDigitDate = "0" + twoDigitDate;
    return fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate;
}

export const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
        return Math.floor(interval) + " Años";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " Meses";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " Días";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " horas";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutos";
    }
    return Math.floor(seconds) + " segundos";
}

export const get_UUID = () => {
    var dt = new Date().getTime();
    var uuid = "xxxx-xxxx-4xxx-yxxx-xxxx".replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
}

export const checkUTF8 = (text) => {
    var utf8Text = text;
    try {
        // Try to convert to utf-8
        utf8Text = decodeURIComponent(escape(text));
        // If the conversion succeeds, text is not utf-8
    } catch (e) {
        // console.log(e.message); // URI malformed
        // This exception means text is utf-8
    }
    return utf8Text; // returned text is always utf-8
}

export const httpGet = (callback) => {
    var wsUrl = "https://ipapi.co/json/";

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }
    fetch(wsUrl, options)
        .then(res => res.json())
        .then(callback)
        .catch(err => console.log(err))
}

export const isValidJSONString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}