import Vuex from 'vuex'
import Vue from 'vue'
import createCompany from "../utils/createParty.js";

const defaultState = () => {
    return {
        company: createCompany(),
        profile: {
            store_id: null,
            token: null,
            token_active: null,
            user_access: null,
            user_email: null,
            user_name: null,
            user_password: null,
            user_photo: null,
        },
        menuStatus: false,
        navStatus: false,
        menuItems: [],
        loading: false,
        host: "",
        toast: {
            timeout_error: 2000,
            dialog: true,
            text: "",
            color: "success",
        }
    }
}

Vue.use(Vuex)

export default new Vuex.Store({
    state: defaultState(),
    mutations: {
        setProfile(state, profile) {
            state.profile = profile;
        },
    },
    actions: {
        setloading(context, value) {
            context.state.loading = value
        },
        setToast(context, value) {
            context.state.toast = value
        },
        setHost(context, host) {
            context.state.host = host
        },
        setProfile(context, profile) {
            // context.state.profile = profile
            context.commit("setProfile", profile)
        },
        setCompany(context, value) {
            // context.state.profile = profile
            context.state.company = value
        },
        setNavStatus(context, value) {
            context.state.navStatus = value
        },
        set_menu(context, value) {
            context.state.menuStatus = value
        },
        set_menuItems(context, value) {
            context.state.menuItems = value
        },
    },
    getters: {
        getLoading(state) {
            return state.loading
        },
        getHost(state) {
            return state.host
        },
        getToast(state) {
            return state.toast
        },
        company(state) {
            return state.company
        },
        profileUserid(state) {
            return state.profile.userid
        },
        profile(state) {
            return state.profile
        },
        profileName(state) {
            return state.profile.name
        },
        profilePicture(state) {
            return state.profile.picture
        },

        getMenuItems: state => state.menuItems,
        getMenuStatus: state => state.menuStatus,
        getNavStatus: state => state.navStatus,
    }
})