<template>
<v-container>
    <v-btn
      large
      dark
      color="info"
      v-if="deferredPrompt"
      ref="addBtn"
      @click="clickCallback"
    >
      Instalar APP
      <v-icon right dark>mdi-android</v-icon>
    </v-btn>
</v-container>
</template>

<script>
export default {
  name: "AddToHomeScreen",
  data: () => ({
    deferredPrompt: null,
  }),
  mounted() {
    this.captureEvent();
  },
  methods: {
    captureEvent() {
      window.addEventListener("beforeinstallprompt", (e) => {
        // ! Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });
    },
    clickCallback() {
      // Show the prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // Call another function?
        }
        this.deferredPrompt = null;
      });
    },
  },
};
</script>