import Vue from 'vue'
import App from './App.vue'
import router from './router'

import vuetify from './plugins/vuetify';

import store from './store'
import 'chart.js/dist/Chart.css'
import VueGoogleCharts from 'vue-google-charts'
Vue.use(VueGoogleCharts)


import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

import './registerServiceWorker'

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')