<template>
  <v-container fluid class="pa-0">
    <v-app-bar :color="color" dark app>
      <v-app-bar-nav-icon>
        <v-img contain height="50" :src="this.$store.getters.company.logo"></v-img>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        {{ this.$store.getters.company.name }}
        <!--  -->
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tabs v-if="!isXs">
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-spacer></v-spacer>
        <v-tab v-for="item in menu" :key="item.name">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <div class="pr-3" v-if="!isXs">
        <v-btn dark color="success" to="/Login">Ingresar</v-btn>
      </div>
      <div v-if="isXs">
        <v-btn fab small @click="dialog = !dialog">
          <v-icon> mdi-dots-vertical</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer dark v-model="dialog" v-if="isXs" fixed right width="300px" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">NGM - Admin</v-list-item-title>
          <v-list-item-subtitle>Task Tracking</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-treeview
        return-object
        item-key="id"
        hoverableS
        activatable
        :open="open"
        open-on-click
        @update:active="updateForm"
        @update:open="updateOpen"
        :items="menu"
        dark
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="!item.icon">
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
          <v-icon :color="item.color" v-else>
            {{ icons[item.icon] }}
          </v-icon>
        </template>
      </v-treeview>
      <v-btn text dark block color="warning" to="/Login">Ingresar</v-btn>
    </v-navigation-drawer>

    <v-carousel cycle height="100vh">
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-sheet color="transparent" height="100%" tile>
          <v-layout fill-height align-center justify-center class="banner">
            <v-flex xs8 sm8 md6>
              <v-row class="fill-height" align="center" justify="center">
                <v-col md="12">
                  <div class="text-h3 white--text">
                    <h1 class="text-md-h2 text-h3 font-weight-bold textShadow">
                      {{ item.title }}
                    </h1>
                    <p class="text-md-h3 text-h6 textShadow">{{ item.text }}</p>
                  </div>
                </v-col>
                <!-- <v-col md="12" class="text-center">
                  <v-btn dark x-large bottom center class="success" to="Registration">Registrese GRATIS AQUI !</v-btn>
                </v-col> -->
              </v-row>
            </v-flex>
          </v-layout>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-divider></v-divider>
    <v-layout fill-height align-center justify-center>
      <v-flex xs12 sm8 md10 align-center justify-center> </v-flex>
    </v-layout>
    <!-- <h1 class="text-md-h2 text-h3 pa-12 text-center font-weight-bold">Servicios</h1>
    <v-layout fill-height align-center justify-center>
      <v-flex xs12 sm8 md10 align-center justify-center>
        <v-row fill-height>
          <v-col cols="12" sm="6" md="6" lg="4">
            <v-card height="100%" elevation="0">
              <v-card-title class="text-md-h3 text-h4 pa-6">Negocios</v-card-title>
              <v-img
                cover
                height="250"
                class="imgsize"
                src="../../assets/immac0.jpg"
              ></v-img>
              <v-card-text class="text-justify">
                <ul>
                  <li>Acceso a listas de precios actualizadas.</li>
                  <li>Gestionar pedidos desde una sola plataforma.</li>
                  <li>Histórico de pedidos por proveedor.</li>
                  <li>Ahorro de tiempo.</li>
                  <li>Trazabilidad de los pedidos.</li>
                </ul>

                Al generar una orden de compra, la plataforma envía a cada proveedor de la
                lista, el pedido correspondiente. Evitando así tener que llamar a diario
                para confirmar precios y disponibilidad , se evita tener que enviar a cada
                proveedor un email, un whatsapp o tener que llamarlo para dictar el
                pedido. Fácil, rápido y efectivo.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="4" align="left" justify="center">
            <v-card height="100%" elevation="0">
              <v-card-title class="text-md-h3 text-h4 pa-6">Proveedores</v-card-title>
              <v-img
                cover
                height="250"
                class="imgsize"
                src="../../assets/immac2.jpg"
              ></v-img>
              <v-card-text class="text-justify">
                <ul>
                  <li>Lista de precios actualizada</li>
                  <li>Recibir pedidos en linea.</li>
                  <li>Trazabilidad de pedidos desde su orden hasta su entrega.</li>
                  <li>Facturación electrónica</li>
                  <li>Recibir pagos en línea.</li>
                </ul>
                Nuestra plataforma le permite atender clientes mayoristas de forma
                eficiente.<br />
                Listas de precios actualizadas en tiempo real.
                <br />
                Mejora los tiempos de respuesta, mejora hasta en un 80% sus ventas y los
                indicadores de satisfacción.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="4" align="left" justify="center">
            <v-card height="100%" elevation="0">
              <v-card-title class="text-md-h3 text-h4 pa-6">Reparto</v-card-title>
              <v-img
                cover
                height="250"
                class="imgsize"
                src="../../assets/immac3.jpg"
              ></v-img>
              <v-card-text class="text-justify">
                Le permite gestionar su propio servicio de domicilios.<br />
                Seguimiento en tiempo real de sus repartidores y tiempos de entrega.<br />
                Seguimiento del estado de los pedidos en tiempo real.<br />
                Generación de documento electrónico de entrega.<br />
                El cliente tienea cceso a sus despachos y conoce con antelación la
                disponibilidad de los productos fecha y hora de entrega.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-spacer class="pa-12"></v-spacer> -->

    <v-footer dark>
      <v-card flat tile width="100%" color="transparent" class="white--text text-center">
        <v-card-text class="white--text">
          <v-row align="center" justify="center">
            <v-col cols="auto">
              New Global Market Corporation.<br />
              info@newglobalmarket.ca
            </v-col>
            <v-col cols="auto">
              NGM Barranquilla<br />
              barranquilla@newglobalmarket.ca
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-btn class="text-capitalize font-weight-light white--text" to="About" text>
            Terminos y condiciones
          </v-btn>
          <v-btn class="text-capitalize font-weight-light white--text" text>
            Políticas
          </v-btn>
          <v-btn class="text-capitalize font-weight-light white--text" text>
            Características del producto
          </v-btn>
          <v-btn class="text-capitalize font-weight-light white--text" text>
            Seguridad
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text text-capitalize font-weight-light">
          {{ new Date().getFullYear() }} — New Global Market Corporation.
        </v-card-text>
      </v-card>
    </v-footer>
    <v-bottom-navigation
      dark
      horizontal
      color="secondary"
      v-if="$vuetify.breakpoint.xsOnly"
      app
    >
      <v-btn color="green" block to="/Login">
        <v-icon>mdi-login</v-icon> Iniciar sesión
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
//

// import { webserver, get_UUID, getToday } from "../services/webserver.js";
// import createItem from "../utils/createItem.js";
// import createParty from "../utils/createParty.js";
// import qrCard from "../components/qrCard.vue";
// import SocialLogin from "../components/Login.vue";
// import BarCodeReader from "../components/BarCodeReader.vue";
// import Stripe from "../components/Stripe.vue";

//import * as db from "../firebaseDb";
//import { getDatabase, ref, set } from "firebase/database";
//const database = getDatabase();
//import { printDoc } from "../utils/escpos.js"; getFirestore, , getDocs

export default {
  name: "Welcome",
  components: {},
  data() {
    return {
      fab: null,
      color: "",
      flat: null,
      isXs: false,
      menu: [
        {
          id: 0,
          name: "Inicio",
          icon: "home",
          to: "/",
          color: "warning",
        },
        {
          id: 2,
          name: "Clientes",
          icon: "employees",
          color: "warning",
          to: "Inventario",
        },
        {
          id: 30,
          name: "Proveedores",
          icon: "supplier",
          color: "warning",
          to: "Inventario",
        },
        {
          id: 40,
          name: "Reparto",
          icon: "truck",
          color: "warning",
          to: "Inventario",
        },
      ],
      items: [
        {
          src: require("../../assets/immac0.jpg"),
          title: "NGM APP",
          text: "Gestione sus obras desde su celular.",
        },
        {
          src: require("../../assets/immac2.jpg"),
          title: "Seguimiento en tiempo real",
          text: "Registre todos los eventos de su obra.",
        },
        {
          src: require("../../assets/immac3.jpg"),
          title: "Reportes de obra.",
          text: "",
        },
      ],
      dialog: false,
      icons: {
        project: "mdi-text-box-search-outline",
        weather: "mdi-weather-partly-snowy-rainy",
        crops: "mdi-sprout-outline",
        poly: "mdi-vector-polygon",
        dB: "mdi-database-cog-outline",
        chart: "mdi-chart-bell-curve-cumulative",
        clt: "mdi-account-circle-outline",
        set: "mdi-cog-transfer-outline",
        info: "mdi-book-information-variant",
        cale: "mdi-calendar-month-outline",
        invoice: "mdi-receipt",
        pedido: "mdi-file-document-edit-outline",
        truck: "mdi-truck-fast-outline",
        warehouse: "mdi-warehouse",
        inventory: "mdi-package-variant-closed",
        supplier: "mdi-truck-check-outline",
        orders: "mdi-file-document-edit-outline",
        store: "mdi-store-outline",
        clock: "mdi-clock-check-outline",
        map: "mdi-map-marker-radius",
        home: "mdi-home-circle-outline",
        forklift: "mdi-forklift",
        cash: "mdi-cash-register",
        employees: "mdi-account-multiple-outline",
        account: "mdi-account-lock-outline",
        accountGroup: "mdi-account-group-outline",
        account_tie: "mdi-account-tie",
        fish: "mdi-fish",
        cube_not: "mdi-package-variant-closed-remove",
        cube_pos: "mdi-package-variant-plus",
        garage: "mdi-garage-variant",
        currency: "mdi-currency-usd",
      },
      open: [],
    };
  },
  mounted() {
    this.$store.dispatch("setNavStatus", false);
    this.$store.dispatch("set_menu", false);
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.$vuetify.goTo(0);
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    updateForm(item) {
      //if (item.length > 0) console.log(item[0].name, item[0].to);
      if (item[0].to) this.$router.push(item[0].to);
    },
    updateOpen(openItems) {
      this.open = openItems.length ? [openItems[openItems.length - 1]] : [];
    },
    logout() {
      this.$store.state.navStatus = false;
      this.$router.push("/login/");
    },
  },
};
</script>

<style>
.textShadow {
  text-shadow: rgb(0, 0, 0) 5px 0 15px;
}

.v-toolbar__title {
  padding-left: 0px !important;
  width: 150px !important;
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}

.header {
  background: rgba(0, 0, 0, 0.877);
  height: 100vh;
  color: white;
}

.banner {
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.607));
}

.services {
  padding-top: 30px;
  padding-bottom: 30px;
}

.supplier {
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.578)),
    url("../../assets/supermarket.jpg") no-repeat center center;
  /* background: #cfd8dc; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.delivery {
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.61)),
    url("../../assets/delivery.jpg") no-repeat center center;
  /* background: #cfd8dc; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.delivery2 {
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.692)),
    url("../../assets/delivery2.jpg") no-repeat center center;
  /* background: #cfd8dc; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.coffee {
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.692)),
    url("../../assets/coffee.jpg") no-repeat center center;
  /* background: #cfd8dc; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.suscribe {
  background: #1a1446;
  height: 50vh;
  color: white;
}

.imgsize {
  height: 40vh;
}

.qr4 {
  height: 80vh;
  /* color: #cddb26; */
}

@media only screen and (max-width: 600px) {
  .header {
    height: 100vh;
  }

  .services {
    height: 100vh;
  }

  .supplier {
    height: 100vh;
  }

  .qr4 {
    font-family: "Teko", sans-serif;
    height: 100vh;
  }
}
</style>
